export enum Action {
    // AI
    UPDATE_SUGGESTED_LABEL_LIST = '@@UPDATE_SUGGESTED_LABEL_LIST',
    UPDATE_REJECTED_SUGGESTED_LABEL_LIST = '@@UPDATE_REJECTED_SUGGESTED_LABEL_LIST',
    UPDATE_OBJECT_DETECTOR_STATUS = '@@UPDATE_OBJECT_DETECTOR_STATUS',
    UPDATE_POSE_DETECTOR_STATUS = '@@UPDATE_POSE_DETECTOR_STATUS',
    UPDATE_DISABLED_AI_FLAG = '@@UPDATE_DISABLED_AI_FLAG',

    // GENERAL
    UPDATE_PROJECT_DATA = '@@UPDATE_PROJECT_DATA',
    UPDATE_WINDOW_SIZE = '@@UPDATE_WINDOW_SIZE',
    UPDATE_ACTIVE_POPUP_TYPE = '@@UPDATE_ACTIVE_POPUP_TYPE',
    UPDATE_CUSTOM_CURSOR_STYLE = '@@UPDATE_CUSTOM_CURSOR_STYLE',
    UPDATE_CONTEXT = '@@UPDATE_CONTEXT',
    UPDATE_PREVENT_CUSTOM_CURSOR_STATUS = '@@UPDATE_PREVENT_CUSTOM_CURSOR_STATUS',
    UPDATE_IMAGE_DRAG_MODE_STATUS = '@@UPDATE_IMAGE_DRAG_MODE_STATUS',
    UPDATE_CROSS_HAIR_VISIBLE_STATUS = '@@UPDATE_CROSS_HAIR_VISIBLE_STATUS',
    UPDATE_REVERSE_LINE_COLOR = '@@UPDATE_REVERSE_LINE_COLOR',
    UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS = '@@UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS',
    UPDATE_ZOOM = '@@UPDATE_ZOOM',
    UPDATE_ENV = '@@UPDATE_ENV',
    ADD_POPUP_MESSAGE = '@@ADD_POPUP_MESSAGE',
    UPDATE_HREF = '@@UPDATE_HREF',

    // LABELS
    UPDATE_LABEL_ACTIVE_IMAGE_INDEX = '@@UPDATE_LABEL_ACTIVE_IMAGE_INDEX',
    UPDATE_LABEL_IMAGE_DATA_BY_ID = '@@UPDATE_LABEL_IMAGE_DATA_BY_ID',
    ADD_LABEL_IMAGES_DATA = '@@ADD_LABEL_IMAGES_DATA',
    UPDATE_LABEL_IMAGES_DATA = '@@UPDATE_LABEL_IMAGES_DATA',
    UPDATE_LABEL_ACTIVE_LABEL_NAME_ID = '@@UPDATE_LABEL_ACTIVE_LABEL_NAME_ID',
    UPDATE_LABEL_ACTIVE_LABEL_TYPE = '@@UPDATE_LABEL_ACTIVE_TYPE',
    UPDATE_LABEL_ACTIVE_LABEL_ID = '@@UPDATE_LABEL_ACTIVE_LABEL_ID',
    UPDATE_LABEL_HIGHLIGHTED_LABEL_ID = '@@UPDATE_LABEL_HIGHLIGHTED_LABEL_ID',
    UPDATE_LABEL_NAMES = '@@UPDATE_LABEL_NAMES',
    UPDATE_LABEL_FIRST_LABEL_CREATED_FLAG = '@@UPDATE_LABEL_FIRST_LABEL_CREATED_FLAG',

    // TEXTS
    UPDATE_TEXT_ACTIVE_IMAGE_INDEX = '@@UPDATE_TEXT_ACTIVE_IMAGE_INDEX',
    UPDATE_TEXT_IMAGE_DATA_BY_ID = '@@UPDATE_TEXT_IMAGE_DATA_BY_ID',
    ADD_TEXT_IMAGES_DATA = '@@ADD_TEXT_IMAGES_DATA',
    UPDATE_TEXT_IMAGES_DATA = '@@UPDATE_TEXT_IMAGES_DATA',
    UPDATE_TEXT_ACTIVE_TEXT_ID = '@@UPDATE_TEXT_ACTIVE_TEXT_ID',
    UPDATE_TEXT_HIGHLIGHTED_TEXT_ID = '@@UPDATE_TEXT_HIGHLIGHTED_TEXT_ID',
    UPDATE_TEXTS = '@@UPDATE_TEXTS',

    // RANK
    UPDATE_RANK_ACTIVE_IMAGE_INDEX = '@@UPDATE_RANK_ACTIVE_IMAGE_INDEX',
    UPDATE_RANK_IMAGE_DATA_BY_ID = '@@UPDATE_RANK_IMAGE_DATA_BY_ID',
    ADD_RANK_IMAGES_DATA = '@@ADD_RANK_IMAGES_DATA',
    UPDATE_RANK_IMAGES_DATA = '@@UPDATE_RANK_IMAGES_DATA',
    
    // NOTIFICATIONS
    SUBMIT_NEW_NOTIFICATION = '@@SUBMIT_NEW_NOTIFICATION',
    DELETE_NOTIFICATION_BY_ID = '@@DELETE_NOTIFICATION_BY_ID',

    // PIPELINES
    UPDATE_PIPELINE_NAME = '@@UPDATE_PIPELINE_NAME',
    UPDATE_PIPELINE_TYPE = '@@UPDATE_PIPELINE_TYPE',
    UPDATE_PIPELINE_SCRIPT_MODE = '@@UPDATE_PIPELINE_SCRIPT_MODE',
    UPDATE_PIPELINE_INDUSTRIAL_MODEL = '@@UPDATE_PIPELINE_INDUSTRIAL_MODEL',
    UPDATE_PIPELINE_TRAINING_JOB_NAME = '@@UPDATE_PIPELINE_TRAINING_JOB_NAME',
    UPDATE_PIPELINE_TRAINING_JOB_INSTANCE_TYPE = '@@UPDATE_PIPELINE_TRAINING_JOB_INSTANCE_TYPE',
    UPDATE_PIPELINE_TRAINING_JOB_INSTANCE_COUNT = '@@UPDATE_PIPELINE_TRAINING_JOB_INSTANCE_COUNT',
    UPDATE_PIPELINE_TRAINING_JOB_VOLUME_SIZE_IN_GB = '@@UPDATE_PIPELINE_TRAINING_JOB_VOLUME_SIZE_IN_GB',
    UPDATE_PIPELINE_TRAINING_JOB_INPUT_DATA = '@@UPDATE_PIPELINE_TRAINING_JOB_INPUT_DATA',
    UPDATE_PIPELINE_TRAINING_JOB_HYPERPARAMETERS = '@@UPDATE_PIPELINE_TRAINING_JOB_HYPERPARAMETERS', 
    UPDATE_PIPELINE_TRAINING_JOB_OUTPUT_S3URI = '@@UPDATE_PIPELINE_TRAINING_JOB_OUTPUT_S3URI',
    UPDATE_PIPELINE_MODEL_NAME = '@@UPDATE_PIPELINE_MODEL_NAME',
    UPDATE_PIPELINE_MODEL_ALGORITHM = '@@UPDATE_PIPELINE_MODEL_ALGORITHM',
    UPDATE_PIPELINE_MODEL_ENVIRONMENT = '@@UPDATE_PIPELINE_MODEL_ENVIRONMENT',
    UPDATE_PIPELINE_MODEL_MODELPACKAGE_GROUP_NAME = '@@UPDATE_PIPELINE_MODEL_MODELPACKAGE_GROUP_NAME',
    UPDATE_PIPELINE_MODEL_MODELPACKAGE_ARN = '@@UPDATE_PIPELINE_MODEL_MODELPACKAGE_ARN',
    UPDATE_PIPELINE_ENDPOINT_NAME = '@@UPDATE_PIPELINE_ENDPOINT_NAME',
    UPDATE_PIPELINE_ENDPOINT_INSTANCE_TYPE = '@@UPDATE_PIPELINE_ENDPOINT_INSTANCE_TYPE',
    UPDATE_PIPELINE_ENDPOINT_ACCELERATOR_TYPE = '@@UPDATE_PIPELINE_ENDPOINT_ACCELERATOR_TYPE',
    UPDATE_PIPELINE_ENDPOINT_INITIAl_INSTANCE_TYPE = '@@UPDATE_PIPELINE_ENDPOINT_JOB_INSTANCE_TYPE',
    UPDATE_PIPELINE_ENDPOINT_INITIAL_VARIANT_WEIGHT = '@@UPDATE_PIPELINE_ENDPOINT_INITIAL_VARIANT_WEIGHT',
    UPDATE_PIPELINE_API_NAME = '@@UPDATE_PIPELINE_API_NAME',
    UPDATE_PIPELINE_API_REST_API_NAME = '@@UPDATE_PIPELINE_API_REST_API_NAME',
    UPDATE_PIPELINE_API_REST_API_ID = '@@UPDATE_PIPELINE_API_REST_API_ID',
    UPDATE_PIPELINE_API_TYPE = '@@UPDATE_PIPELINE_API_TYPE',
    UPDATE_PIPELINE_API_PATH = '@@UPDATE_PIPELINE_API_PATH',
    UPDATE_PIPELINE_API_STAGE = '@@UPDATE_PIPELINE_API_STAGE',
    UPDATE_PIPELINE_API_FUNCTION = '@@UPDATE_PIPELINE_API_FUNCTION',
    UPDATE_PIPELINE_API_METHOD = '@@UPDATE_PIPELINE_API_METHOD',
    UPDATE_PIPELINE_GREENGRASS_COMPONENT_NAME = '@@UPDATE_PIPELINE_GREENGRASS_COMPONENT_NAME',
    UPDATE_PIPELINE_GREENGRASS_COMPONENT_VERSION = '@@UPDATE_PIPELINE_GREENGRASS_COMPONENT_VERSION',
    UPDATE_PIPELINE_MODEL_DATA_URL = '@@UPDATE_PIPELINE_MODEL_DATA_URL',
    UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_NAME = '@@UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_NAME',
    UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_TARGET_TYPE = '@@UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_TARGET_TYPE',
    UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_TARGET_ARN = '@@UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_TARGET_ARN',
    UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_COMPONENTS = '@@UPDATE_PIPELINE_GREENGRASS_DEPLOYMENT_COMPONENTS',
    
    // INDUSTRIAL MODELS
    UPDATE_INDUSTRIAL_MODELS = '@@UPDATE_INDUSTRIAL_MODELS'
}
