export enum ProjectType {
    IMAGE_RECOGNITION = 'IMAGE_RECOGNITION',
    OBJECT_DETECTION = 'OBJECT_DETECTION',
    OBJECT_DETECTION_RECT = 'OBJECT_DETECTION_RECT',
    OBJECT_DETECTION_POINT = 'OBJECT_DETECTION_POINT',
    OBJECT_DETECTION_LINE = 'OBJECT_DETECTION_LINE',
    OBJECT_DETECTION_POLYGON = 'OBJECT_DETECTION_POLYGON',
    TEXT_RECOGNITION = 'TEXT_RECOGNITION',
    IMAGE_GENERIC ='IMAGE_GENERIC'
}

export enum ProjectSubType {
    OBJECT_DETECTION = 'OBJECT_DETECTION',
    IMAGE_TEXT = 'IMAGE_TEXT',
    IMAGE_FLOAT = 'IMAGE_FLOAT',
    IMAGE_CLASS = 'IMAGE_CLASS',
    IMAGE_PREVIEW = 'IMAGE_PREVIEW',
    BATCH_OBJECT_DETECTION = 'BATCH_OBJECT_DETECTION',
    BATCH_IMAGE_TEXT = 'BATCH_IMAGE_TEXT',
    BATCH_IMAGE_FLOAT = 'BATCH_IMAGE_FLOAT',
    BATCH_IMAGE_CLASS = 'BATCH_IMAGE_CLASS',
}
